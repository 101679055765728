import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import SingleCard from "../components/SingleCard";
import Context from "../store/context";

const MainLatest = styled.div`
  margin: 8rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 5rem;
  padding: 4rem;
`;
export default function Latest({ pageCategory }) {
  const { state, dispatch } = useContext(Context);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    checkPageCategory();
  }, [state.posts.length, pageCategory]);

  const checkPageCategory = () => {
    if (state.posts.length > 0) {
      if (pageCategory !== "latest") {
        let filtered = state.posts.filter(
          post => post.category === pageCategory
        );
        setPosts(filtered);
        console.log("TCL: Latest -> filtered", filtered);
      } else if (pageCategory === "latest") {
        setPosts(state.posts);
      }
    }
  };

  if (state.posts.length === 0) {
    return <p>Loading...</p>;
  } else if (pageCategory) {
    return (
      <MainLatest>
        {posts.map(latest => (
          <SingleCard
            key={latest._id}
            id={latest._id}
            author={latest.author}
            authorId={latest.authorId}
            content={latest.content}
            smallImage={latest.smallImage}
            largeImage={latest.largeImage}
            title={latest.title}
            category={latest.category}
          />
        ))}
      </MainLatest>
    );
  } else {
    return <p>Nothing here!</p>;
  }
}
