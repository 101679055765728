//
// ────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: C O L O R   V A R I A B L E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────

export const color = {
  primary: "#44318D",
  primaryDark: "#2A1B3D",
  primaryDarker: "#251836",
  primaryLight: "#8365A5",

  primaryHexa: "68,49,141",
  secondaryHexa: "216,63,135",

  secondary: "#D83F87",

  greyLight1: "#f9f7f6",
  greyLight2: "#bfc9cb",

  greyDark1: "#93a1a3",
  greyDark2: "#A4B3B6"
};

//
// ────────────────────────────────────────────────────────────────── I ──────────
//   :::::: G R I D   T E M P L A T E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────
//
export const gridTemplateColumns = `
  grid-template-columns:
  [full-start] minmax(5rem, 1fr)
  [center-start] repeat(9, [col-start] minmax(min-content, 14rem) [col-end])
  [center-end] minmax(5rem, 1fr) [full-end];
`;
export const gridTemplateColumnsInner = `
  grid-template-columns: repeat(9, minmax(min-content, 14rem));
  
`;

//
// ──────────────────────────────────────────────────────────────────── I ──────────
//   :::::: F O N T   V A R I A B L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────
//

// export const font = {
//   primary: "'Nunito', sans-serif",
//   display: "'Josefin Sans', sans-serif"
// };

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: M A R G I N   V A R I A B L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

//   export const mb = {
//     sm: "margin-bottom: 2rem;",
//     md: "margin-bottom: 3rem;",
//     lg: "margin-bottom: 4rem;",
//     xl: "margin-bottom: 8rem;"
//   };

//
// ────────────────────────────────────────────────────────────────── I ──────────
//   :::::: M E D I A   Q U E R I E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────
//

// 1200px
export const bpLargest = content =>
  `@media (max-width:75em){
        ${content}
      }`;

// 1000px
export const bpLarge = content =>
  `@media (max-width:62.5em){
        ${content}
      }`;
// 800px
export const bpMedium = content =>
  `@media (max-width:50em){
        ${content}
      }`;
// 600px
export const bpSmall = content =>
  `@media (max-width:37.5em){
        ${content}
      }`;
