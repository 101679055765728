import React, { useContext, useReducer, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";

import Context from "./store/context";
import Reducer from "./store/reducer";
import { loadUser, fetchPosts } from "./store/actions";

const Store = () => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(Reducer, initialState);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchPosts(state, dispatch);
  }, []);

  useEffect(() => {
    checkToken();
  }, [token]);

  const checkToken = () => {
    if (token) loadUser(state, dispatch);
  };

  return (
    <Context.Provider value={{ state, dispatch }}>
      <App />
    </Context.Provider>
  );
};

ReactDOM.render(<Store />, document.getElementById("root"));
