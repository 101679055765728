import React from "react";

const Context = React.createContext({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  posts: []
});

export default Context;
