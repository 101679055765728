import React, { useContext, useEffect, useState } from "react";
import Context from "../store/context";
import { fetchPosts } from "../store/actions";
import styled from "styled-components";

const MainSinglePost = styled.div`
  h1 {
    text-align: center;
    font-size: 5rem;
    margin-bottom: 2rem;
  }
  .mainSingleImage {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
  }
  .content {
    font-size: 2rem;
    padding: 2rem 4rem;
  }
`;

export default function SinglePost({ match }) {
  const { state, dispatch } = useContext(Context);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [largeImage, setLargeImage] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    checkPosts();
  }, []);

  useEffect(() => {
    setPost();
  }, [state.posts.length]);

  const checkPosts = () => {
    if (state.posts.length === 0 && loading) {
      fetchPosts(state, dispatch);
      setLoading(true);
    }
    if (state.posts.length > 0 && !loading) {
      console.log("TCL: setPost -> state.posts.length", state.posts.length);
      setLoading(true);
      setPost();
    }
  };
  const setPost = () => {
    if (state.posts.length > 0 && loading) {
      setLoading(false);

      const postId = match.params.id;
      const post = state.posts.filter(singlePost => singlePost._id === postId);
      post.map(thePost => {
        setTitle(thePost.title);
        setAuthor(thePost.author);
        setContent(thePost.content);
        setCategory(thePost.category);
        setLargeImage(thePost.largeImage);
        return null;
      });
    }
  };
  if (loading) {
    return <p>loading...</p>;
  } else {
    return (
      <MainSinglePost>
        <h1>{title}</h1>
        <img
          className="mainSingleImage"
          src={process.env.REACT_APP_SERVER + "/" + largeImage}
          alt={title}
        />
        <p className="content">{content}</p>
      </MainSinglePost>
    );
  }
}
