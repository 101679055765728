import React, { useState, useEffect, useContext } from "react";
import { Container } from "../styles/components/LoginRegister";
import Context from "../store/context";
import { register, login } from "../store/actions";

export default function LoginRegister(props) {
  const [toggle, setToggle] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const { dispatch } = useContext(Context);
  const history = props.history;

  const handleRegisterSubmit = e => {
    e.preventDefault();
    const payload = JSON.stringify({
      name: name,
      email: email,
      password: password
    });
    register(dispatch, payload, history);
  };
  const handleLoginSubmit = e => {
    e.preventDefault();
    const payload = JSON.stringify({
      email: email,
      password: password
    });
    login(dispatch, payload, history);
  };

  return (
    <Container>
      <div className="form-wrap">
        <div className="tabs">
          <h3 className="signup-tab">
            <button
              className={toggle ? "active" : ""}
              onClick={() => setToggle(!toggle)}
              disabled={toggle}
            >
              Register
            </button>
          </h3>
          <h3 className="login-tab">
            <button
              className={!toggle ? "active" : ""}
              onClick={() => setToggle(!toggle)}
              disabled={!toggle}
            >
              Login
            </button>
          </h3>
        </div>

        <div className="tabs-content">
          <div id="signup-tab-content" className={toggle ? "active" : ""}>
            <form onSubmit={handleRegisterSubmit}>
              <input
                value={name}
                onChange={e => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
                type="text"
                className="input"
                autoComplete="off"
                placeholder="Username"
              />
              <input
                value={email}
                onChange={e => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                type="email"
                className="input"
                autoComplete="off"
                placeholder="Email"
              />
              <input
                value={password}
                onChange={e => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                type="password"
                className="input"
                autoComplete="off"
                placeholder="Password"
              />
              <input type="submit" className="button" value="Sign Up" />
            </form>
            <div className="help-text">
              <p>By signing up, you agree to our</p>
              <p>
                <a href="#">Terms of service</a>
              </p>
            </div>
          </div>

          <div id="login-tab-content" className={!toggle ? "active" : ""}>
            <form onSubmit={handleLoginSubmit}>
              <input
                value={email}
                onChange={e => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                type="email"
                className="input"
                autoComplete="off"
                placeholder="Email"
              />
              <input
                value={password}
                onChange={e => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                type="password"
                className="input"
                autoComplete="off"
                placeholder="Password"
              />
              {/* <input type="checkbox" className="checkbox" id="remember_me" />
              <label htmlFor="remember_me">Remember me</label> */}

              <input type="submit" className="button" value="Login" />
            </form>
            <div className="help-text">
              <p>
                <a href="/">Forget your password?</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
