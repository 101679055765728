import React, { useState, useContext } from "react";
import styled from "styled-components";
import { color } from "../styles/_base";
import Context from "../store/context";
import { addNewPost } from "../store/actions";

const NewPostContainer = styled.div`
  form {
    padding: 2rem;
    input[type="text"],
    select,
    textarea {
      width: 100%;
      padding: 1.2rem 2rem;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    textarea {
      min-height: 20rem;
    }
    input[type="submit"] {
      font-size: 1.6rem;
      text-transform: uppercase;
      width: 100%;
      background-color: ${color.primary};
      color: white;
      padding: 1.4rem 2rem;
      margin: 8px 0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    input[type="submit"]:hover {
      background-color: ${color.primaryLight};
    }
    .category {
      display: inline-block;
      position: relative;
      padding-left: 3.5rem;
      margin-bottom: 1.2rem;
      cursor: pointer;
      font-size: 2.2rem;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: #eee;
        border-radius: 50%;
      }
      &:hover input ~ .checkmark {
        background-color: #ccc;
      }
      input:checked ~ .checkmark {
        background-color: #2196f3;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
`;

export default function NewPostPage({ history }) {
  const [title, setTitle] = useState("");
  const [picture, setPicture] = useState("");
  const [pictureName, setPictureName] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("react");
  const { state, dispatch } = useContext(Context);
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("postImage", picture);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("category", category);
    formData.append("author", state.user.name);
    formData.append("authorId", state.user._id);
    addNewPost(dispatch, formData, history);
  };

  return (
    <NewPostContainer>
      <form onSubmit={handleSubmit}>
        <input
          value={title}
          onChange={e => {
            e.preventDefault();
            setTitle(e.target.value);
          }}
          type="text"
          autoComplete="off"
          placeholder="Title"
        />
        <textarea
          value={content}
          onChange={e => {
            e.preventDefault();
            setContent(e.target.value);
          }}
          type="text"
          autoComplete="off"
          placeholder="Content"
        />
        <input
          onChange={e => {
            e.preventDefault();
            setPicture(e.target.files[0]);
            console.log(e.target.files[0]);
          }}
          type="file"
          placeholder="Select an image"
        />
        {/* <select name="category">
          <option value="r1eact">React</option>
          <option value="mongoDB">MongoDB</option>
          <option value="other">Other</option>
        </select> */}
        <div className="category">
          <label>
            React
            <input
              name="radio"
              value="react"
              type="radio"
              onChange={() => setCategory("react")}
              defaultChecked
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="category">
          <label>
            MongoDB
            <input
              value="mongodb"
              name="radio"
              type="radio"
              onChange={() => setCategory("mongodb")}
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="category">
          <label>
            Other
            <input
              value="other"
              name="radio"
              type="radio"
              onChange={() => setCategory("other")}
            />
            <span className="checkmark" />
          </label>
        </div>

        <input type="submit" className="button" value="Add Post" />
      </form>
    </NewPostContainer>
  );
}
