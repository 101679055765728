import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { color, gridTemplateColumns } from "../styles/_base";
import Context from "../store/context";

const AppTopBar = styled.div`
  background-color: ${color.primaryDarker};
  color: white;
  grid-column: full-start / full-end;
  display: grid;
  ${gridTemplateColumns}

  justify-content: center;
  align-content: center;
  .logo {
    grid-column: 2/3;
  }
  .right {
    grid-column: 3 / center-end;
    justify-self: end;
  }
  a {
    font-size: 1.5rem;
    font-weight: 300;
    text-decoration: none;
    margin-right: 2rem;
    :visited,
    :link {
      color: ${color.greyLight2};
    }
    :active,
    :hover {
      color: ${color.secondary};
    }
  }
  a.active {
    :visited,
    :link {
      color: ${color.secondary};
    }
    :active,
    :hover {
      color: ${color.secondary};
    }
  }
`;

export default function TopBar({ logout }) {
  const { state, dispatch } = useContext(Context);
  const [loggedUser, setloggedUser] = useState(false);

  const isAuthenticated = () => {
    if (localStorage.getItem("token") && state.isAuthenticated) {
      setloggedUser(true);
    } else setloggedUser(false);
  };
  useEffect(() => {
    isAuthenticated();
  }, [state.isAuthenticated]);
  const authLinks = (
    <>
      <Link to="/new-post" onClick={logout}>
        Add Post
      </Link>
      <Link to="/" onClick={() => dispatch({ type: "LOGOUT_SUCCESS" })}>
        Logout
      </Link>
    </>
  );

  const guestLinks = (
    <>
      <Link to="/auth">Register / Login</Link>
    </>
  );

  return (
    <AppTopBar>
      <div className="logo">
        <Link to="/">LaroBlog</Link>
      </div>
      <nav className="right">{loggedUser ? authLinks : guestLinks}</nav>
    </AppTopBar>
  );
}
