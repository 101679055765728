import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../styles/_base";
import { Button } from "./Common";
import Context from "../store/context";
import { deletePost } from "../store/actions";

const CardWrap = styled.div`
  background-color: ${color.greyLight1};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem;
  position: relative;
  img {
    width: 100%;
    grid-row: 1/2;
    grid-column: 1/-1;
    z-index: 1;
    height: 30rem;
    object-fit: cover;
  }
  h5 {
    justify-self: center;
    align-self: end;
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 80%;
    font-size: 1.6rem;
    text-align: center;
    padding: 1.25rem;
    background-color: ${color.secondary};
    color: #ffffff;
    font-weight: 400;
    z-index: 2;
    transform: translateY(50%);
  }
  p {
    grid-column: 1/-1;
    min-height: 5rem;
  }
  .LinkButton {
    width: 100%;
  }
  > a {
    grid-column: 1/-1;
  }
  .delete {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    color: white;
    font-size: 1.8rem;
    border: none;
    background-color: red;
    height: 3rem;
    width: 3rem;
    z-index: 2;
  }
`;

export default function Card({
  title,
  largeImage,
  smallImage,
  category,
  content,
  author,
  authorId,
  id
}) {
  const { state, dispatch } = useContext(Context);

  if (authorId && state.user && state.user._id === authorId) {
    const handleDelete = () => {
      deletePost(state, dispatch, id);
      console.log("clicked");
    };
    return (
      <CardWrap>
        <img
          src={process.env.REACT_APP_SERVER + "/" + smallImage}
          alt={title}
        />
        <h5>{category.toUpperCase()}</h5>
        <p className="title">{title} </p>
        <Link to={`/single-post/${id}`}>
          <Button className="LinkButton">Read More</Button>
        </Link>
        <button onClick={handleDelete} className="delete">
          X
        </button>
      </CardWrap>
    );
  } else {
    return (
      <CardWrap>
        <img
          src={process.env.REACT_APP_SERVER + "/" + smallImage}
          alt={title}
        />
        <h5>{category.toUpperCase()}</h5>
        <p className="title">{title} </p>
        <Link to={`/single-post/${id}`}>
          <Button className="LinkButton">Read More</Button>
        </Link>
      </CardWrap>
    );
  }
}
