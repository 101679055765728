import axios from "axios";

export const fetchPosts = async (state, dispatch) => {
  // User loading
  dispatch({ type: "POSTS_LOADING" });
  console.log("Posts loading...");

  axios
    .get(process.env.REACT_APP_SERVER + "/api/posts", config())
    .then(res => {
      console.log("Posts loaded!");
      dispatch({
        type: "POSTS_LOADED",
        payload: res.data
      });
    })
    .catch(err => {
      // dispatch(returnErrors(err.response.data, err.response.status));
      console.log("Error loading posts!");
      dispatch({
        type: "POSTS_ERROR"
      });
    });
};
export const deletePost = async (state, dispatch, postId) => {
  // User loading
  dispatch({ type: "LOADING_POST_DELETE" });
  console.log("LOADING_POST_DELETE...");

  axios
    .delete(process.env.REACT_APP_SERVER + "/api/posts/" + postId, config())
    .then(res => {
      console.log("Post deleted!");
      const thePosts = state.posts.filter(post => post._id !== postId);
      dispatch({
        type: "POST_DELETED",
        payload: thePosts
      });
    })
    .catch(err => {
      // dispatch(returnErrors(err.response.data, err.response.status));
      console.log("Error deleting postE!");
      dispatch({
        type: "DELETE_POST_ERROR"
      });
    });
};

export const loadUser = async (state, dispatch) => {
  // User loading
  dispatch({ type: "USER_LOADING" });
  console.log("User loading...");

  axios
    .get(process.env.REACT_APP_SERVER + "/api/auth/user", config())
    .then(res => {
      console.log("User loaded!");
      dispatch({
        type: "USER_LOADED",
        payload: res.data
      });
    })
    .catch(err => {
      // dispatch(returnErrors(err.response.data, err.response.status));
      console.log("Invalid token!");
      dispatch({
        type: "AUTH_ERROR"
      });
    });
};
export const register = async (dispatch, payload, history) => {
  // Beginning Register
  console.log("Beginning Register...", payload);

  axios
    .post(process.env.REACT_APP_SERVER + "/api/users", payload, config())
    .then(res => {
      console.log("REGISTER_SUCCESS...");
      dispatch({
        type: "REGISTER_SUCCESS",
        payload: res.data
      });
      history.push("/");
    })
    .catch(err => {
      //   dispatch(
      //     returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      //   );
      console.log("REGISTER_FAIL...", err);
      dispatch({ type: "REGISTER_FAIL" });
    });
};
export const login = async (dispatch, payload, history) => {
  // Beginning Logging In
  console.log("Beginning Logging In...", payload);

  axios
    .post(process.env.REACT_APP_SERVER + "/api/auth", payload, config())
    .then(res => {
      console.log("LOGIN_SUCCESS...");
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data
      });
      console.log(history);
      history.push("/");
    })
    .catch(err => {
      //   dispatch(
      //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
      //   );
      console.log("LOGIN_FAIL...", err);
      dispatch({ type: "LOGIN_FAIL" });
    });
};

export const addNewPost = async (dispatch, payload, history) => {
  // Beginning addNewPost
  console.log("Beginning addNewPost...", payload);

  axios
    .post(process.env.REACT_APP_SERVER + "/api/posts", payload, config())
    .then(res => {
      console.log("POST_SUCCESS...");
      dispatch({
        type: "POST_SUCCESS",
        payload: res.data
      });
      console.log(history);
      history.push("/");
    })
    .catch(err => {
      //   dispatch(
      //     returnErrors(err.response.data, err.response.status, "POST_FAIL")
      //   );
      console.log("POST_FAIL...", err);
      dispatch({ type: "POST_FAIL" });
    });
};

export const config = () => {
  // Get token from localstorage
  const token = localStorage.getItem("token");

  // Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  return config;
};
