import styled from "styled-components";
import { color } from "../styles/_base";

const HeadingCommon = `
font-weight:400;
`;

export const Heading1 = styled.h1`
  ${HeadingCommon};
  font-size: 4.5rem;
  color: ${color.greyLight1};
  line-height: 1;
`;
export const Heading2 = styled.h2`
  ${HeadingCommon};
  font-size: 4rem;
  color: ${props =>
    props.light
      ? color.greyLight1
      : props.dark
      ? color.greyDark1
      : color.primary};
  line-height: 1;
`;
export const Heading3 = styled.h3`
  ${HeadingCommon};
  font-size: 1.6rem;
  color: ${color.primary};
  text-transform: uppercase;
`;
export const Heading4 = styled.h4`
  ${HeadingCommon};
  font-size: 1.9rem;
  color: ${props =>
    props.light
      ? color.greyLight1
      : props.dark
      ? color.greyDark1
      : color.primary};
`;

export const Button = styled.button`
  background-color: ${color.primary};
  color: #ffffff;
  border: none;
  border-radius: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.8rem 3rem;
  cursor: pointer;
  transition: all 0.2s;

  ${props => props.header && "align-self:start; justify-self:start;"}
  :hover {
    background-color: ${color.primaryDark};
  }
`;
