import React from "react";
import GlobalStyle from "../styles/_global";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import { gridTemplateColumns, color } from "../styles/_base";
import TopBar from "./TopBar";

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 3rem 30vh min-content;
  /* grid-auto-rows: minmax(min-content, 14rem); */
  ${gridTemplateColumns}
background-color: ${color.primaryLight};
min-height: 100vh;
`;
const PageContainer = styled.div`
  margin: 3rem 0;
  grid-column: center-start/center-end;
  background-color: ${color.greyLight1};
  box-shadow: 0px 0px 46px -3px rgba(0, 0, 0, 0.83);
  border-radius: 5px;
`;

export default function MainStructure({ children }) {
  return (
    <>
      <GlobalStyle />
      <MainContainer>
        <TopBar />
        <NavBar />
        <PageContainer>{children}</PageContainer>
      </MainContainer>
    </>
  );
}
