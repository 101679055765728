import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { color, gridTemplateColumns } from "../styles/_base";

const AppNavBar = styled.div`
  background-color: ${color.primaryDark};
  color: white;
  grid-column: full-start / full-end;
  display: grid;
  ${gridTemplateColumns}

  justify-content: center;
  align-content: center;

  .content {
    grid-column: center-start/center-end;
    justify-self: center;
  }
  p {
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    margin-right: 1rem;
    color: ${color.greyLight2};
  }
  a {
    font-size: 40px;
    font-weight: 300;
    text-decoration: none;
    margin-right: 1rem;
    :visited,
    :link {
      color: ${color.greyLight2};
    }
    :active,
    :hover {
      color: ${color.secondary};
    }
  }
  a.active {
    :visited,
    :link {
      color: ${color.secondary};
    }
    :active,
    :hover {
      color: ${color.secondary};
    }
  }
`;

export default function NavBar() {
  return (
    <AppNavBar>
      <nav className="content">
        <NavLink to="/" exact>
          Latest
        </NavLink>
        <p>/</p>
        <NavLink to="/react" exact>
          React
        </NavLink>
        <p>/</p>
        <NavLink to="/mongodb" exact>
          MongoDB
        </NavLink>
        <p>/</p>
        <NavLink to="/other" exact>
          Other
        </NavLink>
      </nav>
    </AppNavBar>
  );
}
