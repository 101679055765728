import { createGlobalStyle } from "styled-components";
import { color } from "./_base";

const GlobalStyle = createGlobalStyle`

*{
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing:border-box;
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
 background-color: ${color.greyLight1};
}
  background-repeat:no-repeat;
  min-height:100vh;

  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${color.primary}; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${color.primaryDarker}; 
}
`;
export default GlobalStyle;
