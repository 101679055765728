import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import CategoryPage from "./pages/CategoryPage";
import MainStructure from "./components/MainStructure";
import LoginRegister from "./components/LoginRegister";
import history from "./history";
import Context from "./store/context";
import NewPostPage from "./pages/NewPostPage";
import SinglePost from "./pages/SinglePost";

export default function App() {
  const { state } = useContext(Context);
  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        state.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
  return (
    <Router history={history}>
      <>
        <MainStructure>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <CategoryPage {...props} pageCategory="latest" />
              )}
            />
            <Route exact path="/single-post/:id" component={SinglePost} />
            <Route
              exact
              path="/react"
              render={props => <CategoryPage {...props} pageCategory="react" />}
            />
            <Route
              exact
              path="/mongodb"
              render={props => (
                <CategoryPage {...props} pageCategory="mongodb" />
              )}
            />
            <Route
              exact
              path="/other"
              render={props => <CategoryPage {...props} pageCategory="other" />}
            />
            <Route exact path="/auth" component={LoginRegister} />

            <AdminRoute path="/new-post" component={NewPostPage} />
          </Switch>
        </MainStructure>
      </>
    </Router>
  );
}
