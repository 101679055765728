import styled from "styled-components";
import { color } from "../_base";

export const Container = styled.div`
  font: 1.8rem/2.6rem "Raleway", sans-serif;
  a {
    color: #666;
    text-decoration: none;
  }
  a:hover {
    color: ${color.primaryLight};
  }

  input {
    font: 2rem/2.4rem "Raleway", sans-serif;
  }

  .form-wrap {
    background-color: #fff;
    max-width: 600px;
    margin: 3em auto;
    box-shadow: 0px 1px 8px #bebebe;
    -webkit-box-shadow: 0px 1px 8px #bebebe;
    -moz-box-shadow: 0px 1px 8px #bebebe;
    .tabs {
      overflow: hidden;
      h3 {
        float: left;
        width: 50%;
        button {
          padding: 1.5rem 0;
          text-align: center;
          font-weight: 400;
          background-color: #e6e7e8;
          display: block;
          color: #666;
          width: 100%;
          font-size: 2rem;
          border-right: none;
        }
        button.active {
          background-color: #fff;
        }
      }
    }
    .tabs-content {
      padding: 1.5em;
      .active {
        display: block !important;
      }
    }
  }
  .form-wrap .tabs-content div[id$="tab-content"] {
    display: none;
  }
  .form-wrap form .input {
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    padding: 0.8rem 0 1rem 0.8rem;
    border: 1px solid #cfcfcf;
    outline: 0;
    display: inline-block;
    margin: 0 0 0.8em 0;
    padding-right: 2rem;
    width: 100%;
  }
  .form-wrap form .button {
    width: 100%;
    padding: 0.8rem 0 1rem 0.8rem;
    background-color: ${color.primary};
    border: none;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
  }
  .form-wrap form .button:hover {
    background-color: ${color.primaryLight};
  }
  .form-wrap form .checkbox {
    visibility: hidden;
    padding: 2rem;
    margin: 0.5rem 0 1.5rem;
  }
  .form-wrap form .checkbox:checked + label:after {
    filter: alpha(opacity=100);
    opacity: 1;
  }
  .form-wrap form label[for] {
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    :before {
      content: "";
      position: absolute;
      border: 1px solid #cfcfcf;
      width: 1.7rem;
      height: 1.7rem;
      top: 0px;
      left: -1.4rem;
    }
    :after {
      filter: alpha(opacity=0);
      opacity: 0;
      content: "";
      position: absolute;
      width: 0.9rem;
      height: 0.5rem;
      background-color: transparent;
      top: 4px;
      left: -1rem;
      border: 3px solid ${color.primaryLight};
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
    }
  }
  .form-wrap .help-text {
    margin-top: 0.6rem;
    p {
      text-align: center;
      font-size: 1.4rem;
    }
  }
`;
